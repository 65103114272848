import { Box, FormLabel, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../../app/defaultConnect';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import styles from './styles';


class StepComponent extends Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    sendState() { return {} }

    isValidated() { return true; }

    getGroupName() {
        const { allStates, audience } = this.props;

        if (!allStates.schedule) return '';

        const group = audience.find((g: any) => g.key == allStates.schedule.contactGroup);
        if (group) {
            return group.label;
        }
        return '-- Invalid group --';
    }

    isValidScheduleDate() {
        const { allStates } = this.props;

        return allStates.schedule.scheduledate instanceof moment && allStates.schedule.scheduledate.isValid();
    }


    render() {
        const { allStates, classes } = this.props;

        return (
            <Box>
                <Box className={classes.stepperTitle}>
                    <h4>Let's verify your information is correct, you won't be able to edit this message.</h4>
                </Box>

                <GridContainer>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={clsx(classes.labelHorizontal, classes.labelReview)}>
                            Message
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                        <div className={classes.staticFormGroup}>
                            <p className={classes.staticFormControl}>
                                {allStates && allStates.write && allStates.write.message}
                            </p>
                        </div>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={clsx(classes.labelHorizontal, classes.labelReview)}>
                            Target Audience
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                        <div className={classes.staticFormGroup}>
                            <p className={classes.staticFormControl}>
                                {this.getGroupName()}
                            </p>
                        </div>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={clsx(classes.labelHorizontal, classes.labelReview)}>
                            Schedule
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                        <div className={classes.staticFormGroup}>
                            <p className={classes.staticFormControl}>
                                {allStates && allStates.schedule && allStates.schedule.schedule === 'now' ? 'Send message immediately' : 'Send message in the future'}
                            </p>
                        </div>
                    </GridItem>
                </GridContainer>

                {allStates && allStates.schedule && allStates.schedule.schedule === 'future' &&
                    <GridContainer>
                        <GridItem xs={12} sm={2}>
                            <FormLabel className={clsx(classes.labelHorizontal, classes.labelReview)}>
                                Schedule Date
                        </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                            <div className={classes.staticFormGroup}>
                                <p className={classes.staticFormControl}>
                                    {this.isValidScheduleDate() ? allStates.schedule.scheduledate.format('YYYY-MM-DD HH:mm Z') : '-- Invalid date --'}
                                </p>
                            </div>
                        </GridItem>
                    </GridContainer>}
            </Box>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StepComponent));