import { createStyles, Theme } from "@material-ui/core";
import { container } from '../../assets/jss/material-dashboard-react'

const drawerWidth = 240;

export const styles = ({ spacing, breakpoints, mixins, transitions, zIndex }: Theme) => createStyles({
    wrapper: {
        position: "relative",
        top: "0",
        height: "100vh"
    },
    mainPanel: {
        [breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        // ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch",
        flex: 1
    },
    container: {
        ...container,
        marginTop: "100px",
        height: '100%',
        flex: "1 1 auto"
    },
    content: {
        padding: "30px 15px",
        paddingTop: "0px",
        paddingRight: "0px",
        minHeight: "calc(100vh - 123px)",
        flex: 1
    },
});