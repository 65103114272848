import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "../../assets/jss/material-dashboard-react/components/headerStyle.js";
import Button from "../CustomButtons/Button.js";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";


const useStyles = makeStyles(styles);

export default function Header(props) {
	const classes = useStyles(),
		{ history, title } = props;

	function makeBrand() {

		if (title) return title;

		let name;
		props.routes.map(prop => {
			if (window.location.href.indexOf(prop.path) !== -1) {
				name = props.rtlActive ? prop.rtlName : prop.name;
			}
			return null;
		});
		return name;
	}

	const { color } = props;
	const appBarClasses = classNames({
		[" " + classes[color]]: color
	});
	return (
		<AppBar className={classes.appBar + appBarClasses}>
			<Toolbar className={classes.container}>
				<div className={classes.flex}>
					<Button color="transparent" href="#" className={classes.title}>
						{makeBrand()}
					</Button>
				</div>
				<Hidden smDown implementation="css">
					{props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks history={history} />}
				</Hidden>
				<Hidden mdUp implementation="css">
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={props.handleDrawerToggle}
					>
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
}

Header.propTypes = {
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
	rtlActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	routes: PropTypes.arrayOf(PropTypes.object),
	history: PropTypes.any,
	title: PropTypes.string
};
