import React, { Component, createContext } from "react";
import firebase from 'firebase';

export const UserContext = createContext({ user: null });

class UserProvider extends Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            user: null
        };
    }

    componentDidMount = () => {
        firebase.auth().onAuthStateChanged(userAuth => {
            this.setState({ user: userAuth });
        });
    };

    render() {
        return (
            <UserContext.Provider value={this.state.user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
export default UserProvider;