import { Box, FormControl, FormControlLabel, FormLabel, Radio, withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import Datetime from "react-datetime";
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../../app/defaultConnect';
import { BaseComponent } from '../../../components/BaseComponent';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { CustomSweetAlert } from '../../../components/CustomSweetAlert';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import styles from './styles';


class StepComponent extends BaseComponent<any, any> {
    constructor(props: any) {
        super(props);

        const now = moment(),
            minutesToNextTick = 5 - (now.minute() % 5),
            defaultDate = now.add(minutesToNextTick === 0 ? 5 : minutesToNextTick, 'minutes');

        this.state = {
            contactGroup: "",
            schedule: 'now',
            scheduledate: defaultDate
        };
    }

    sendState() {
        return this.state;
    }

    isValidated() {
        const { contactGroup, schedule, scheduledate } = this.state;

        if (!contactGroup) {
            this.alert('Please select an audience for your message.');
            return false;
        }

        const now = moment();
        if (schedule === 'future' && scheduledate.isBefore(now)) {
            this.alert('Please select a valid schedule date.');
            return false;
        }

        return true;
    }

    updateValue(key: string, value: any) {
        this.setState({ [key]: value });
    }

    render() {
        const { classes } = this.props,
            { contactGroup, schedule, scheduledate } = this.state;

        return (
            <Box>
                <Box className={classes.stepperTitle}>
                    <h4>Let's select your target audience and schedule your message</h4>
                </Box>
                <GridContainer>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal} required>
                            Target Audience
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        <CustomInput
                            id="contactGroup"
                            type="select"
                            formControlProps={{
                                fullWidth: true,
                                required: true
                            }}
                            data={this.props.audience || []}
                            inputProps={{
                                onChange: (evt: any) => this.updateValue('contactGroup', evt.target.value),
                                value: contactGroup
                            }}
                        />
                    </GridItem>
                    <GridItem sm={1}>
                        &nbsp;
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={clsx(classes.labelHorizontal, classes.labelHorizontalRadioCheckbox)} required>
                            Schedule
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        <Box className={clsx(classes.checkboxAndRadio, classes.checkboxAndRadioHorizontal)}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={schedule === "now"}
                                        onChange={(evt: any) => this.updateValue('schedule', evt.target.value)}
                                        value="now"
                                        name="schedule"
                                        aria-label="now"
                                        icon={
                                            <FiberManualRecord className={classes.radioUnchecked} />
                                        }
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked} />
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot
                                }}
                                label="Send message immediately"
                            />
                        </Box>
                        <Box className={clsx(classes.checkboxAndRadio, classes.checkboxAndRadioHorizontal)}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={schedule === "future"}
                                        onChange={(evt: any) => this.updateValue('schedule', evt.target.value)}
                                        value="future"
                                        name="schedule"
                                        aria-label="schedule"
                                        required
                                        icon={
                                            <FiberManualRecord className={classes.radioUnchecked} />
                                        }
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked} />
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot
                                }}
                                label="Send message in the future"
                            />
                        </Box>
                    </GridItem>
                    <GridItem sm={1}>
                        &nbsp;
                    </GridItem>
                </GridContainer>

                <Collapse in={schedule === "future"}>
                    <GridContainer>
                        <GridItem xs={12} sm={2}>
                            <FormLabel className={clsx(classes.labelHorizontal, classes.labelScheduleDate)} required>
                                Schedule Date
                        </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={9}>
                            <FormControl fullWidth
                                className={classes.formControl}>
                                <Datetime
                                    input
                                    value={scheduledate}
                                    onChange={(value: any) => this.updateValue('scheduledate', value)}
                                    inputProps={{
                                        placeholder: "Select a time to send your message"
                                    }}
                                    isValidDate={(current: moment.Moment) => current.isSame(moment(), 'day') || current.isSameOrAfter(moment())}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem sm={1}>
                            &nbsp;
                    </GridItem>
                    </GridContainer>
                </Collapse>
                <CustomSweetAlert ref={(ref: any) => this.alertComp = ref} />
            </Box>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StepComponent));