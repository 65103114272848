import firebase from 'firebase';

class SessionUtils {

    constructor() {
    }

    isLoggedIn(): boolean {
        return firebase.auth().currentUser !== null;
    }

    getUser(): firebase.User | null {
        return firebase.auth().currentUser;
    }

    getUserToken = async () => {
        return await firebase.auth().currentUser!.getIdToken(true);
    }

    async getRoles(): Promise<Array<string>> {
        const user = await firebase.auth().currentUser;

        if (!user) {
            return [];
        }

        const tokenResult = await user.getIdTokenResult();
        return tokenResult.claims.roles || [];
    }

    async getAccountId(): Promise<string | null> {
        const user = await firebase.auth().currentUser;

        if (!user) {
            return null;
        }

        const tokenResult = await user.getIdTokenResult();
        const id: string = tokenResult.claims.accountId;
        return id || null;
    }

    performLoginWithEmailAndPassword = (email: string, password: string) => {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }

    /**
     * Executes the Logout function.
     * If logged in using a provider like Google or Facebook, it will also disconnect from the provider
     */
    performLogout = async () => {
        const user = firebase.auth().currentUser!;
        const providerData = user.providerData;

        // providerData.forEach(async data => {
        //     switch (data.providerId) {
        //         case 'google.com':

        //             // First, we need to configure Google
        //             await GoogleSignin.signOut();

        //             break;
        //         case 'facebook.com':

        //             LoginManager.logOut()
        //             break;
        //     }
        // });

        // // Now, sign out from firebase
        // RemoteNotifications.unsubscribe();
        return firebase.auth().signOut();
    }

    getErrorMessage(code: string) {
        const screen = 'AUTH';
        let message = 'An unknown error has ocurred. Please try again.';
        switch (code) {
            case 'auth/email-already-in-use':
                message = 'The email address is already in use.'
                break;
            case 'auth/invalid-email':
                message = 'The email is invalid.'
                break;
            case 'auth/operation-not-allowed':
                message = 'The operation you are trying to perform is not allowed';
                break;
            case 'auth/weak-password':
                message = 'The password you have introduced is too weak.'
                break;
            case 'auth/account-exists-with-different-credential':
                message = 'This account already exists.'
                break;
            case 'auth/user-not-found':
                message = 'The user cannot be found.'
                break;
            case 'auth/invalid-credential':
            case 'auth/user-disabled':
            case 'auth/wrong-password':
                message = 'Your login information is incorrect. Please try again.';
                break;
            case 'auth/invalid-verification-id':
            case 'auth/invalid-verification-code':
                message = 'The code you have submitted is invalid.'
                break;
            case 'auth/requires-recent-login':
                message = 'Before continue, please login again.'
                break;
        }

        return message;
    }
}

export default new SessionUtils();