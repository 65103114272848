import { Backdrop, Box, Chip, Grid, Step, StepLabel, Stepper, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import { DropzoneArea } from 'material-ui-dropzone';
import neatCsv from 'neat-csv';
import React, { Component } from 'react';
import SweetSuccessIcon from 'react-bootstrap-sweetalert/dist/components/SuccessIcon';
import { RingLoader } from 'react-spinners';
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from '../../components/Card/CardFooter';
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CustomButton from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';
import { CustomSweetAlert } from '../../components/CustomSweetAlert';
import { VirtualizedTable } from '../../components/InifiniteTable';
import { Dashboard } from '../../components/Layout';
import { getAjaxClient } from '../../utils';
import styles from './styles';
import { ContactSchema } from '../../schemas';

class ContactsUpload extends Component<any, any> {
    private alertComp: any = React.createRef();

    constructor(props: any) {
        super(props);

        this.state = {
            groups: [],
            loading: false,
            activeStep: 0,
            data: {
                contactGroups: []
            },
            files: [],
            csv: {
                columns: [],
                data: []
            }
        }
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.loadGroups()
            .catch(error => {
                let message = 'An error has occurred while loading the required information.';
                this.alert(message);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    async loadGroups(): Promise<void> {
        const client = await getAjaxClient();

        return client.get('/api/v1/contactgroup')
            .then(response => {
                const groups = response.data.map((group: any) => {
                    return {
                        key: group.id,
                        label: group.name
                    }
                });
                this.setState({ groups });
            });
    }

    alert(message: string, callback?: Function) {
        this.alertComp.open({
            content: message,
            type: 'error',
            onClose: callback
        });
    }

    updateValue(key: string, value: any) {
        const data = this.state.data;
        data[key] = value;
        this.setState({ data });
    }

    async next() {
        let { activeStep, csv, data } = this.state;
        const client = await getAjaxClient();

        if (activeStep === 0) {
            this.setState({ loading: true });
            this.processUploadedFile()
                .then((data: any) => {
                    if (data.length === 0) {
                        return this.alert('Could not find any data in your file.');
                    }

                    const columns = Object.keys(data[0]).map(key => {
                        if (data[0].hasOwnProperty(key)) {
                            return {
                                label: key,
                                dataKey: key
                            }
                        }
                        return null;
                    }).filter(col => !!col);

                    for (let i = 0; i < data.length; i++) {
                        const results = ContactSchema.validate(data[i]);
                        if (!results.error) continue;

                        const errors = results.error.details.map((d: any) => d.message);
                        data[i].errors = errors.length > 0 ? errors.join('. ') : '';
                    }

                    console.log(data);

                    this.setState({
                        csv: {
                            data,
                            columns
                        }
                    })
                })
                .catch(error => {
                    this.alert('Could not load the file you have selected, please try again.');
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                        activeStep: activeStep + 1
                    });
                });
        } else if (activeStep === 1) {

            const hasErrors = _.some(csv.data, (row: any) => !!row.errors);

            if (hasErrors) {
                return this.alert('Please verify all your errors before continue.');
            }

            this.setState({
                activeStep: activeStep + 1
            });

        } else if (activeStep === 2) {

            this.setState({ loading: true });

            client.post('/api/v1/contacts/import/json', {
                contacts: csv.data.map((row: any) => {
                    return {
                        ...row,
                        errors: undefined
                    }
                }),
                groupIds: data.contactGroups
            })
                .then(response => {
                    this.setState({ activeStep: activeStep + 1 });
                })
                .catch(error => {
                    this.alert('An error ocurred while importing your data.');
                })
                .finally(() => {
                    this.setState({ loading: false });
                });


        } else {
            this.setState({
                activeStep: activeStep + 1
            });
        }
    }

    processUploadedFile(): Promise<any> {
        const { files } = this.state;

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (evt: any) => {
                resolve(evt.target.result);
            };
            reader.onerror = reject;
            reader.readAsText(files[0]);
        })
            .then((data: any) => {
                return neatCsv(data);
            });
    }

    getFirstStep() {
        const { classes } = this.props;
        return (
            <Box>
                <Box className={classes.stepperTitle}>
                    <h2>Let's start by selecting the file to import</h2>
                    <Typography variant="subtitle2" gutterBottom>
                        Select a file that contains your contacts.
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DropzoneArea
                            filesLimit={1}
                            acceptedFiles={['.csv']}
                            dropzoneClass={classes.dropzone}
                            dropzoneParagraphClass={classes.dropzoneText}
                            showFileNames
                            useChipsForPreview
                            showPreviews
                            showPreviewsInDropzone={false}
                            previewText={"Selected file:"}
                            clearOnUnmount={false}
                            onChange={(files) => {
                                this.setState({ files });
                            }}
                            previewChipProps={{
                                color: 'secondary',
                                variant: 'default',
                                size: 'medium'
                            }}
                            alertSnackbarProps={{
                                autoHideDuration: 2500
                            }}
                        />
                    </Grid>
                </Grid >
            </Box>);
    }

    getSecondStep() {
        const { classes } = this.props;
        const { csv } = this.state;

        return (
            <Box>
                <Box className={classes.stepperTitle}>
                    <h2>Let's review the data you have imported</h2>
                    <Typography variant="subtitle2" gutterBottom>
                        Review your data and assign the uploaded contacts to a group.
                    </Typography>
                </Box>
                {csv.data.length > 0 && <VirtualizedTable
                    data={csv.data}
                    rowCount={csv.data.length}
                    loadMoreRows={() => Promise.resolve([])}
                    columns={[
                        {
                            label: 'Name',
                            dataKey: 'firstname'
                        },
                        {
                            label: 'Last Name',
                            dataKey: 'lastname'
                        },
                        {
                            label: 'Phone',
                            dataKey: 'phone',
                            type: 'phone',
                            classes: (cell: any) => {
                                if (_.some(csv.data, (row, idx, data) => {
                                    return row.phone && row.phone === cell.cellData && idx !== cell.rowIndex;
                                })) {
                                    return classes.warning;
                                }
                                return null;
                            }
                        },
                        {
                            label: 'Country',
                            dataKey: 'country'
                        },
                        {
                            label: 'Errors',
                            dataKey: 'errors',
                            classes: (cell: any) => {
                                return cell.cellData ? classes.uploadTableDuplicateData : null;
                            }
                        }
                    ]}
                />}
                {csv.data.length === 0 &&
                    <Alert severity="error">The file you have selected does not contain any valid data.</Alert>
                }
            </Box>);
    }

    getThirdStep() {
        const { classes } = this.props;
        const { groups } = this.state;
        return (
            <Box>
                <Box className={classes.stepperTitle}>
                    <h2>Let's assign your imported contacts to a group</h2>
                    <Typography variant="subtitle2" gutterBottom>
                        Select the group(s) you want to assign to the contacts.
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={1}>
                        &nbsp;
                    </Grid>
                    <Grid item xs={10}>
                        <CustomInput
                            labelText="Assigned Groups"
                            id="contactGroups"
                            type="select"
                            formControlProps={{
                                fullWidth: true,
                                required: true
                            }}
                            data={this.state.groups || []}
                            inputProps={{
                                onChange: (evt: any) => this.updateValue('contactGroups', evt.target.value),
                                value: this.state.data.contactGroups || [],
                                multiple: true,
                                renderValue: (selected: any) => (
                                    <Box className={classes.uploadChips}>
                                        {selected.map((value: string) => {
                                            const group = groups.find((g: any) => g.key === value);
                                            return (<Chip key={value} color={"secondary"} label={group.label} className={classes.uploadChip} />);
                                        })}
                                    </Box>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        &nbsp;
                    </Grid>
                </Grid >
            </Box>);
    }

    getFourthStep() {
        const { classes } = this.props;
        return (
            <Box>
                <Box className={classes.stepperTitle}>
                    <SweetSuccessIcon />
                    <h2 className={classes.uploadFinalStepTitle}>You are all set</h2>
                    <Typography variant="subtitle2" gutterBottom>
                        All your contacts have been imported.
                    </Typography>
                </Box>
            </Box>);
    }

    getStepperButtons() {
        const { classes } = this.props;
        const { activeStep } = this.state;

        let nextDisabled = false;
        switch (activeStep) {
            case 0:
                nextDisabled = this.state.files.length === 0;
                break;
            case 1:
                nextDisabled = this.state.csv.data.length === 0;
                break;
            case 2:
                nextDisabled = this.state.data.contactGroups.length === 0;
                break;
        }

        return (

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    {activeStep < 3 && <CustomButton
                        color="danger"
                        startIcon={<CloseIcon />}
                        simple
                        onClick={() => this.props.history.push('/contacts')}
                    >
                        Cancel
                    </CustomButton>}
                </Grid>
                <Grid item xs={6}>
                    <Box className={classes.toolbarIcons}>
                        {activeStep > 0 && activeStep < 3 && <CustomButton
                            color="primary"
                            startIcon={<NavigateBeforeIcon />}
                            simple
                            onClick={() => this.setState({ activeStep: activeStep - 1 })}
                        >
                            Previous
                        </CustomButton>}
                        {activeStep < 3 && <CustomButton
                            color="success"
                            endIcon={<NavigateNextIcon />}
                            simple
                            disabled={nextDisabled}
                            onClick={this.next.bind(this)}
                        >
                            Next
                        </CustomButton>}
                        {activeStep === 3 && <CustomButton
                            color="success"
                            startIcon={<DoneOutlinedIcon />}
                            simple
                            onClick={() => this.props.history.push('/contacts')}
                        >
                            Complete
                        </CustomButton>}
                    </Box>
                </Grid>
            </Grid>
        );
    }

    render() {
        const { classes, history } = this.props,
            { activeStep, loading } = this.state;

        return (
            <Dashboard title="Contacts" history={history}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="rose">
                            <ContactsOutlinedIcon />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <RingLoader color="#ffffff" />
                        </Backdrop>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            <Step key="step1">
                                <StepLabel>Select File</StepLabel>
                            </Step>
                            <Step key="step2">
                                <StepLabel>Review Data</StepLabel>
                            </Step>
                            <Step key="step3">
                                <StepLabel>Assign Groups</StepLabel>
                            </Step>
                            <Step key="step4">
                                <StepLabel>Complete</StepLabel>
                            </Step>
                        </Stepper>

                        {activeStep === 0 && this.getFirstStep()}
                        {activeStep === 1 && this.getSecondStep()}
                        {activeStep === 2 && this.getThirdStep()}
                        {activeStep === 3 && this.getFourthStep()}
                    </CardBody>
                    <CardFooter>
                        {this.getStepperButtons()}
                    </CardFooter>
                </Card>
                <CustomSweetAlert ref={(ref: any) => this.alertComp = ref} />
            </Dashboard>);
    }

}



export default withStyles(styles)(ContactsUpload);