import { Box, withStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from '@material-ui/icons/Lock';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import React, { Component } from "react";
import image from "../../assets/img/bg7.jpg";
import styles from "../../assets/jss/material-kit-react/views/loginPage";
import Backdrop from '../../components/Backdrop';
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import { CustomSweetAlert } from '../../components/CustomSweetAlert';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import SessionUtils from '../../utils/auth/SessionUtils';

class LoginPage extends Component<any, any>{
	private alertComp: any = React.createRef();

	constructor(props: any) {
		super(props);
		this.state = {
			cardAnimation: 'cardHidden',
			saving: false,
			form: {
				email: '',
				pwd: ''
			}
		};
	}

	updateState(property: string, value: string) {
		const { form } = this.state;
		form[property] = value;

		this.setState({ form });
	}

	async submit() {
		const { form } = this.state;
		if (!form.email) {
			return this.alert('Please enter your email.');
		}

		if (!form.pwd) {
			return this.alert('Please enter your password.');
		}

		try {

			this.setState({ saving: true });
			
			await SessionUtils.performLoginWithEmailAndPassword(form.email, form.pwd);
			this.setState({ saving: false });
			this.props.history.replace('/');
		}
		catch (e) {
			this.setState({ saving: false });
			this.alert(SessionUtils.getErrorMessage(e.code));
		}
	}

	alert(message: string, callback?: Function) {
		this.alertComp.open({
			content: message,
			type: 'error',
			onClose: callback
		});
	}

	render() {
		const { cardAnimation, saving } = this.state;
		const { classes, ...rest } = this.props;


		return (
			<Box>
				<Box
					className={classes.pageHeader}
					style={{
						backgroundImage: "url(" + image + ")",
						backgroundSize: "cover",
						backgroundPosition: "top center"
					}}
				>
					<Backdrop open={saving}></Backdrop>
					<Box className={classes.container}>
						<GridContainer justify="center">
							<GridItem xs={12} sm={12} md={4}>
								<Card className={classes.cardAnimaton}>
									<form className={classes.form}>
										<CardHeader color="primary" className={classes.cardHeader}>
											<h4>Login</h4>
											<Box className={classes.socialLine}>
												{/* <Button
													justIcon
													// href="#pablo"
													// target="_blank"
													color="transparent"
													onClick={e => e.preventDefault()}
												>
													<i className={"fab fa-twitter"} />
												</Button>
												<Button
													justIcon
													// href="#pablo"
													// target="_blank"
													color="transparent"
													onClick={e => e.preventDefault()}
												>
													<i className={"fab fa-facebook"} />
												</Button>
												<Button
													justIcon
													// href="#pablo"
													// target="_blank"
													color="transparent"
													onClick={e => e.preventDefault()}
												>
													<i className={"fab fa-google-plus-g"} />
												</Button> */}
											</Box>
										</CardHeader>
										<p className={classes.boxider}>Or Be Classical</p>
										<CardBody>
											<CustomInput
												labelText="Email"
												id="first"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "email",
													startAdornment: (
														<InputAdornment position="start">
															<MailOutlineIcon className={classes.inputIconsColor} />
														</InputAdornment>
													),
													value: this.state.form.email,
													onChange: (evt: any) => this.updateState('email', evt.target.value)
												}}
											/>
											<CustomInput
												labelText="Password"
												id="pwd"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													type: "password",
													value: this.state.form.pwd,
													onChange: (evt: any) => this.updateState('pwd', evt.target.value),
													startAdornment: (
														<InputAdornment position="start">
															<LockIcon className={classes.inputIconsColor} />
														</InputAdornment>
													)
												}}
											/>
											<Box>
												<Button color="rose" onClick={() => { this.submit(); }}>
													Login
												</Button>
											</Box>
										</CardBody>
									</form>
								</Card>
							</GridItem>
						</GridContainer>
					</Box>
				</Box>
				<CustomSweetAlert ref={(ref: any) => this.alertComp = ref} />
			</Box>
		);
	}
}

export default withStyles(styles)(LoginPage);