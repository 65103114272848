import React, { Component } from 'react';
import { Backdrop, withStyles } from '@material-ui/core';
import { RingLoader } from 'react-spinners';
import styles from './styles';

class CustomBackdrop extends Component<any, any>{


    render() {
        const { classes, open } = this.props;

        return (
            <Backdrop className={classes.backdrop} open={open}>
                <RingLoader color="#ffffff" />
            </Backdrop>);
    }
}

export default withStyles(styles)(CustomBackdrop);