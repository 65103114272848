import { Theme, StyleRules, createStyles } from "@material-ui/core";

const styles: (theme: Theme) => StyleRules<string> = theme =>
    createStyles({
        toolbar: {
            paddingLeft: 0
        },
        button: {
            margin: theme.spacing(1),
        },
        toolbarIcons: {
            alignItems: 'flex-end',
            textAlign: 'end',
            width: '100%'
        },

        cardSubtitle: {
            "&,& a,& a:hover,& a:focus": {
                color: "rgba(255,255,255,.62)",
                margin: "0",
                fontSize: "14px",
                marginTop: "0",
                marginBottom: "0"
            },
            "& a,& a:hover,& a:focus": {
                color: "#FFFFFF"
            }
        },
        cardTitle: {
            color: "#FFFFFF",
            marginTop: "0px",
            minHeight: "auto",
            fontWeight: 300,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#777",
                fontSize: "65%",
                fontWeight: "400",
                lineHeight: "1"
            }
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        dropzone: {
            minHeight: 'auto !important'
        },

        stepperTitle: {
            textAlign: 'center',
            marginBottom: '20px'
        },
        dropzoneText: {
            fontSize: '1.5em',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 100,
            lineHeight: '1.5em'
        },
        uploadChips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        uploadChip: {
            margin: 2,
        },
        uploadFinalStepTitle: {
            marginTop: 0
        },
        selectedFileChip: {
            margin: 10
        },

        uploadTableDuplicateData: {
            color: 'white',
            background: 'rgb(204, 0, 0, 0.7)'
        },
        warning: {
            color: 'white',
            background: '#ffbb33'
        }

    });

export default styles;