import { Theme, StyleRules, createStyles } from "@material-ui/core";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";


const styles: (theme: Theme) => StyleRules<string> = theme =>
    createStyles({
        ...customCheckboxRadioSwitch,
        charCounter: {
            fontSize: '.8em',
            fontWeight: 400
        },
        toolbarIcons: {
            alignItems: 'flex-end',
            textAlign: 'end',
            width: '100%'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        stepperTitle: {
            textAlign: 'center',
            marginBottom: '20px'
        },
        labelScheduleDate: {
            paddingTop: 16
        },
        labelReview: {
            paddingTop: 0,
            paddingBottom: 30
        }
    });

export default styles;