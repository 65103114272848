import { MuiThemeProvider } from "@material-ui/core";
import { createMuiTheme, createStyles, StyleRules, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import firebase from 'firebase';
import React from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { store } from './app/store';
import { container, drawerWidth, transition } from "./assets/jss/material-dashboard-react.js";
import Backdrop from './components/Backdrop';
import { firebase as firebaseConfig } from './project.config.json';
import { ContactsForm, ContactsList, ContactsUpload } from './pages/Contacts';
import HomePage from './pages/Home';
import LoginHome from './pages/Login';
import MessagesHome from './pages/Messages';
import MessagesSchedule from './pages/Messages/Schedule';
import { getAjaxClient } from './utils';


type AppProps = {} & WithStyles<typeof styles>;
const theme = createMuiTheme({});

// Init firebase app
let authComplete = false
firebase.initializeApp(firebaseConfig);
firebase.auth().onAuthStateChanged(async user => {
    authComplete = true;
    if (!user) return;
    const client = await getAjaxClient();

    client.get('/api/v1/contactgroup')
        .then(response => {

            const audience = response.data.map((group: any) => {
                return {
                    key: group.id,
                    label: group.name
                }
            });
            store.dispatch({ type: 'STORE_AUDIENCE', audience });
        });
});

const styles: (theme: Theme) => StyleRules<string> = theme =>
    createStyles({
        wrapper: {
            position: "relative",
            top: "0",
            height: "100vh"
        },
        mainPanel: {
            [theme.breakpoints.up("md")]: {
                width: `calc(100% - ${drawerWidth}px)`
            },
            overflow: "auto",
            position: "relative",
            float: "right",
            ...transition,
            maxHeight: "100%",
            width: "100%",
            overflowScrolling: "touch"
        },
        content: {
            marginTop: "70px",
            padding: "30px 15px",
            minHeight: "calc(100vh - 123px)"
        },
        container,
        map: {
            marginTop: "70px"
        }
    });

const nonAuthRouter = () => {
    return (
        <Router>
            <Route exact path="/" component={LoginHome} />
            <Route exact path="/login" component={LoginHome} />
        </Router>);
}

const authRouter = (storeAudience: Function) => {
    return (
        <Router>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/messages" component={MessagesHome} />
            <Route exact path="/messages/schedule" component={MessagesSchedule} />
            <Route exact path="/contacts/edit" component={ContactsForm} />
            <Route exact path="/contacts/edit/:id" component={ContactsForm} />
            <Route exact path="/contacts/upload" component={ContactsUpload} />
            <Route exact path="/contacts" component={ContactsList} />
        </Router>);
}


const App = (props: any) => {
    const [user, initialising, error] = useAuthState(firebase.auth());

    return (
        <MuiThemeProvider theme={theme}>
            {(initialising || !authComplete) && <Backdrop open></Backdrop>}
            {(!initialising && authComplete) && user && authRouter(props.storeAudience)}
            {(!initialising && authComplete) && !user && nonAuthRouter()}
        </MuiThemeProvider>
    )
};

export default withStyles(styles)(App);
