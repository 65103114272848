import { withStyles } from '@material-ui/core/styles';
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React, { PureComponent } from 'react';
import logo from "../../assets/img/reactlogo.png";
import bgImage from "../../assets/img/sidebar-2.jpg";
import { IDashboardProps } from '../../interfaces/IDashboardProps';
import { IState } from '../../interfaces/IState';
import routes from "../../routes";
import Footer from '../Footer/Footer';
import Navbar from "../Navbars/Navbar";
import Sidebar from '../Sidebar/Sidebar';
import { styles } from './styles';

let ps: any;

class DashboardBase extends PureComponent<IDashboardProps, IState> {

    mainPanel: any;

    constructor(props: any) {
        super(props);

        this.state = {
            fixedClasses: 'dropdown show',
            mobileOpen: false
        }
    }

    private handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    };

    private resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false })
        }
    };

    componentDidUpdate() {
        const me = this;

        // initialize and destroy the PerfectScrollbar plugin
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(me.mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", me.resizeFunction);
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", me.resizeFunction);
        };
    }

    render() {
        const { classes, history, title } = this.props;

        return (
            <div className={classes.wrapper}>
                <main className={classes.content}>
                    <Sidebar
                        routes={routes}
                        logoText={"DASHBOARD"}
                        logo={logo}
                        image={bgImage}
                        handleDrawerToggle={this.handleDrawerToggle}
                        open={this.state.mobileOpen}
                    />
                    <div className={classes.mainPanel} ref={ref => { this.mainPanel = ref; }}>
                        <Navbar
                            routes={routes}
                            handleDrawerToggle={this.handleDrawerToggle}
                            history={history}
                            title={title}
                        />
                        <div className={classes.content}>
                            <div className={classes.container}>{this.props.children}</div>
                        </div>
                        <Footer />
                    </div>
                </main>
            </div>
        );
    }
}

const Dashboard = withStyles(styles)(DashboardBase);
export { Dashboard };

