import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../../app/defaultConnect';
import { BaseComponent } from '../../../components/BaseComponent';
import { CustomSweetAlert } from '../../../components/CustomSweetAlert';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { Dashboard } from '../../../components/Layout';
import Wizard from '../../../components/Wizard';
import Backdrop from './../../../components/Backdrop';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import styles from './styles';

class ScheduleForm extends BaseComponent<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            groups: [],
            saving: false
        }
    }

    async submit(allStates: any) {
        const client = await this.getAjaxClient();
        
        const data = {
            message: allStates.write.message,
            audienceId: allStates.schedule.contactGroup,
            scheduleType: allStates.schedule.schedule,
            scheduleDate: allStates.schedule.schedule === 'future' ? allStates.schedule.scheduledate.unix() * 1000 : null
        }

        this.setState({ saving: true });

        client.post('/api/v1/message', {
            ...data
        })
            .then(response => {
                this.setState({ saving: false });
                return this.props.history.push('/messages');
            })
            .catch(error => {
                this.setState({ saving: false });
                this.alert('An error has occurred while saving your message.');
            });
    }

    render() {
        const { saving } = this.state;
        const { history } = this.props;

        return (
            <Dashboard title="Schedule Message" history={history}>
                <Backdrop open={saving}></Backdrop>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={8}>
                        <Wizard
                            validate
                            steps={[
                                { stepName: "Write", stepComponent: Step1, stepId: "write" },
                                { stepName: "Schedule", stepComponent: Step2, stepId: "schedule" },
                                { stepName: "Review", stepComponent: Step3, stepId: "review" }
                            ]}
                            title="Schedule your message"
                            subtitle="This wizard will help you to write and schedule your message."
                            finishButtonClick={(e: any) => {
                                this.submit(e);
                            }}
                        />
                    </GridItem>
                </GridContainer>
                <CustomSweetAlert ref={(ref: any) => this.alertComp = ref} />
            </Dashboard>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduleForm));