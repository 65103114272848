import 'moment-timezone/builds/moment-timezone-with-data';
import moment, { Moment } from 'moment-timezone';


class DateUtils {

    private readonly tz: string;

    constructor() {
        this.tz = moment.tz.guess();
    }

    public get now(): Moment {
        return moment.tz(this.tz);
    }

    public get utc(): Moment {
        return moment.tz(this.tz).utc();
    }

    public get timeZone(): string {
        return this.tz;
    }

    /**
     * Returns a moment with the current date disregding the hour (12:00 on current timezone)
     */
    public get today(): Moment {
        return moment.tz(this.tz).startOf('date');
    }

    public format(date: any, format: string) {
        return moment(date).tz(this.tz).format(format);
    }
}

export default new DateUtils();