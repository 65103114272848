import { MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { CountryRegionData } from 'react-country-region-selector/dist/rcrs.es';
import styles from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import { PhoneInput } from './PhoneInput';

const useStyles = makeStyles(styles);



export default function CustomInput(props) {
    const classes = useStyles();
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        success,
        type,
        data
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true
    });
    const marginTop = classNames({
        [classes.marginTop]: labelText === undefined
    });

    const getInputComponent = () => {
        switch (type) {
            case 'phone': return PhoneInput;
            default: return undefined;
        }
    }

    const renderComponent = () => {

        if (type === 'country') {
            return (<Select
                classes={{
                    root: marginTop,
                    disabled: classes.disabled
                }}
                id={id}
                autoWidth
                {...inputProps}
            >
                {CountryRegionData.map((option, index) => (
                    <MenuItem key={option[0]} value={option[1]}>
                        {option[0]}
                    </MenuItem>
                ))}
            </Select>);
        } else if (type === 'select') {
            return (<Select
                id={id}
                autoWidth
                input={<Input />}
                {...inputProps}
            >
                {(data || []).map(item => (
                    <MenuItem key={item.key} value={item.key}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>);
        }

        return (<Input
            classes={{
                root: marginTop,
                disabled: classes.disabled,
                underline: underlineClasses
            }}
            inputComponent={getInputComponent()}
            id={id}
            {...inputProps}
        />);
    }

    return (
        <FormControl
            {...formControlProps}
            className={formControlProps.className + " " + classes.formControl}
        >
            {labelText !== undefined ? (
                <InputLabel
                    className={classes.labelRoot + labelClasses}
                    htmlFor={id}
                    {...labelProps}
                >
                    {labelText}
                </InputLabel>
            ) : null}
            {renderComponent()}
            {error ? (
                <Clear className={classes.feedback + " " + classes.labelRootError} />
            ) : success ? (
                <Check className={classes.feedback + " " + classes.labelRootSuccess} />
            ) : null}
        </FormControl>
    );
}

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool,
    type: PropTypes.oneOf(['phone', 'country', 'select']),
    data: PropTypes.array
};