import { Box, Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { BaseComponent } from '../../../components/BaseComponent';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { CustomSweetAlert } from '../../../components/CustomSweetAlert';
import PrimaryTypography from '../../../components/Typography/Primary';
import styles from './styles';

class StepComponent extends BaseComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            message: ""
        };
    }

    sendState() {
        return this.state;
    }

    isValidated() {
        const { message } = this.state;
        const isValid = message.length >= 3;

        if (!isValid) {
            this.alert('Please enter a valid message.')
        }
        return isValid;
    }

    handleMessageChange(evt: any) {

        let { message } = this.state;
        message = evt.target.value;

        this.setState({ message });
    }

    render() {
        const { classes } = this.props,
            { message } = this.state;
        const maxChars = 1000,
            messageLength = message.length,
            remaining = maxChars - message.length,
            messageHelperText = `Remaining ${remaining} out of ${maxChars} characters.`;

        return (
            <Box>
                <Box className={classes.stepperTitle}>
                    <h4>Let's write a clear short message for your audience</h4>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <CustomInput
                            labelText="Message"
                            id="message"
                            success={messageLength > 3}
                            formControlProps={{
                                fullWidth: true,
                                required: true
                            }}
                            inputProps={{
                                value: message,
                                multiline: true,
                                rowsMax: 4,
                                rows: 4,
                                onChange: this.handleMessageChange.bind(this)
                            }}
                        />
                        <PrimaryTypography className={classes.charCounter}>
                            {messageHelperText}
                        </PrimaryTypography>
                    </Grid>
                </Grid >
                <CustomSweetAlert ref={(ref: any) => this.alertComp = ref} />
            </Box>);
    }

}

export default withStyles(styles)(StepComponent);