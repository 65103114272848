import { Box, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import libphonenumber from 'google-libphonenumber';
import React from 'react';
import { AutoSizer, Column, ColumnSizer, InfiniteLoader, Table, TableHeaderProps } from 'react-virtualized';
import { v4 as uuid } from 'uuid';
import { DateUtils } from '../../utils/';
import CustomLinearProgress from '../CustomLinearProgress/CustomLinearProgress';
import { ColumnData, ColumnType, IState, MuiVirtualizedTableProps, Row, styles } from './interfaces';
import numeral from 'numeral';


declare module '@material-ui/core/styles/withStyles' {
    // Augment the BaseCSSProperties so that we can control jss-rtl
    interface BaseCSSProperties {
        /*
         * Used to control if the rule-set should be affected by rtl transformation
         */
        flip?: boolean;
    }
}


class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps, IState> {

    static defaultProps = {
        headerHeight: 48,
        rowHeight: 48,
    };

    private promise = Promise.resolve();
    private continueLoading = true;
    private tableRef: any;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            selected: {}
        };
    }

    componentDidMount() {
        const { tableRef } = this.props;
        if (tableRef) {
            tableRef(this);
        }
    }

    private getRowClassName = ({ index }: Row) => {
        const { classes, onRowClick } = this.props;

        return clsx(classes.tableRow, classes.flexContainer, {
            [classes.tableRowHover]: index !== -1 && !!!onRowClick,
        });
    };

    public getSelected(): Array<string> {
        const { selected } = this.state;
        return Object.keys(selected);
    }


    // #region Individual renderers

    private dataRenderer(column: ColumnData, params: any) {

        if (column.renderer) {
            return column.renderer(column, params.rowData);
        }

        switch (column.type) {
            case ColumnType.boolean:
                return this.renderBooleanColumn(column, params.cellData);

            case ColumnType.date:
                return this.renderDateColumn(column, params.cellData);

            case ColumnType.phone:
                return this.renderPhoneColumn(column, params.cellData);

            case ColumnType.select:
                return this.renderSelectColumn(column, params);

            case ColumnType.number:
                return numeral(params.cellData).format(column.format || "0,0.00")

            default:
                return String(params.cellData || '');
        }
    }

    private renderBooleanColumn(column: ColumnData, cellData: any) {
        return (<Checkbox
            checked={cellData}
            color="primary"
        />);
    }

    private renderDateColumn(column: ColumnData, cellData: any) {
        return DateUtils.format(cellData, column.format || 'MM/DD/YYYY hh:mm:ss z');
    }

    private renderPhoneColumn(column: ColumnData, cellData: any) {
        if (!cellData)
            return '';

        const util = libphonenumber.PhoneNumberUtil.getInstance();
        const number = util.parse(cellData || '', 'US')

        return util.format(number, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
    }

    private renderSelectColumn(column: ColumnData, params: any) {
        const { selected } = this.state,
            idValue = this.getIdValue(params.rowData);

        return (<Checkbox
            checked={selected[idValue] || false}
            color="primary"
            onClick={(evt: any) => {
                const value = evt.target.checked;

                if (!value)
                    delete selected[idValue];
                else
                    selected[idValue] = evt.target.checked;

                this.setState({ selected });

                if (this.tableRef)
                    this.tableRef.forceUpdate();
            }}
        />);
    }

    private getIdValue(rowData: any) {
        const { id } = this.props;

        let idValue = rowData[id || 'id'];
        if (!idValue) {
            idValue = uuid();
            rowData['___id'] = idValue;
        }

        return idValue;
    }

    // #endregion


    private cellRenderer(column: ColumnData, cellRendererParams: any) {
        const { classes, rowHeight, onRowClick } = this.props;
        let cellClass: any = undefined;

        if (column.classes) {
            cellClass = typeof column.classes === 'function' ? column.classes(cellRendererParams) :
                column.classes;
        }

        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                }, cellClass)}
                variant="body"
                style={{ height: rowHeight }}
                align={this.getColumnAlign(column)}
            >
                {this.dataRenderer(column, cellRendererParams)}
            </TableCell>
        );
    }

    private headerRenderer = (headerProps: TableHeaderProps, columnIndex: number, column: ColumnData) => {
        const { headerHeight, classes } = this.props;

        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight }}
                align={this.getColumnAlign(column)}
            >
                <span>{headerProps.label}</span>
            </TableCell>
        );
    };

    getColumnAlign(column: ColumnData): "left" | "right" | "inherit" | "center" | "justify" | undefined {
        let align: "left" | "right" | "inherit" | "center" | "justify" | undefined = 'left';
        switch (column.type) {
            case ColumnType.number:
                align = 'right';
                break;
            case ColumnType.select:
                align = 'center';
                break;
        }
        align = column.align || align;

        return align;
    }

    // #region Data loading

    private loadMoreRows({ startIndex, stopIndex }: { startIndex: number, stopIndex: number }): Promise<void> {

        // Return a promise that resolves empty after loading
        if (this.state.loading) {
            return this.promise
                .then(() => this.loadMoreRows({ startIndex, stopIndex }));
        }

        // If the stop index is not the last row, return an empty promise
        // As there is no need to load more data
        if (stopIndex < this.props.rowCount - 1 || !this.continueLoading) {
            return Promise.resolve();
        }

        // Now, start loading
        this.setState({ loading: true });

        this.promise = this.props
            .loadMoreRows({ startIndex, stopIndex })
            .then(data => {
                this.continueLoading = data.length > 0;
            })
            .finally(() => {
                this.setState({ loading: false });
            });


        return this.promise;
    }

    private isRowLoaded({ index }: { index: number }): boolean {
        return this.props.isRowLoaded ? this.props.isRowLoaded({ index }) : !!this.props.data[index];
    }

    // #endregion

    render() {
        const { classes, columns, rowHeight, headerHeight, selectable, id, ...tableProps } = this.props;

        let selectableColumn: any = null;
        if (selectable === true) {
            let selectableColumnProps: ColumnData = { dataKey: id || 'id', type: 'select' };
            selectableColumn = (<Column
                key={id || 'id'}
                headerRenderer={(headerProps) =>
                    this.headerRenderer(headerProps, -1, selectableColumnProps)
                }
                className={classes.flexContainer}
                cellRenderer={(p) => this.cellRenderer(selectableColumnProps, p)}
                dataKey={selectableColumnProps.dataKey}
                {...selectableColumnProps}
                width={65}
            />);
        }

        return (
            <Box>
                {this.state.loading && <CustomLinearProgress color="rose" />}
                <AutoSizer disableHeight>
                    {({ height, width }) => (
                        <ColumnSizer
                            columnMaxWidth={500}
                            columnMinWidth={100}
                            columnCount={columns.length}
                            width={width}>
                            {({ adjustedWidth, getColumnWidth, registerChild }) => (
                                <InfiniteLoader
                                    isRowLoaded={({ index }) => this.isRowLoaded({ index })}
                                    loadMoreRows={this.loadMoreRows.bind(this)}
                                    rowCount={this.props.rowCount+1}
                                >
                                    {({ onRowsRendered, registerChild }) => (
                                        <Table
                                            ref={(ref: any) => this.tableRef = ref}
                                            estimatedColumnSize={getColumnWidth()}
                                            height={this.props.height || height || 550}
                                            width={width}
                                            rowHeight={rowHeight!}
                                            headerHeight={headerHeight!}
                                            className={classes.table}
                                            {...tableProps}
                                            onRowsRendered={onRowsRendered}
                                            rowGetter={({ index }) => this.props.data[index]}
                                            rowClassName={this.getRowClassName}
                                        >
                                            {selectable && selectableColumn}
                                            {columns.map((column, index) => {
                                                return (
                                                    <Column
                                                        key={column.dataKey}
                                                        headerRenderer={(headerProps) =>
                                                            this.headerRenderer(headerProps, index, column)
                                                        }
                                                        className={classes.flexContainer}
                                                        cellRenderer={(p) => this.cellRenderer(column, p)}
                                                        dataKey={column.dataKey}
                                                        {...column}
                                                        width={column.width || getColumnWidth()}
                                                    />
                                                );
                                            })}
                                        </Table>
                                    )}
                                </InfiniteLoader>
                            )}
                        </ColumnSizer>
                    )}
                </AutoSizer>
            </Box>
        );
    }
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

export { VirtualizedTable };

