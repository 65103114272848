import React, { Component } from 'react';
import { Dashboard } from '../../components/Layout';

class HomePage extends Component<any, any> {


    render() {
        const { history } = this.props;

        return (
            <Dashboard history={history}>
                <div>Hello</div>
            </Dashboard>);
    }

}

export default HomePage;