
import { storeAudience } from './actions';

const mapStateToProps = (state: any) => ({
    audience: state.audience
});

const mapDispatchToProps = (dispatch: any) => ({
    storeAudience: (audience: Array<any>) => dispatch(storeAudience(audience))
});

export { mapDispatchToProps, mapStateToProps };