import { Backdrop, Box, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';
import { RingLoader } from 'react-spinners';
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from '../../components/Card/CardFooter';
import CardHeader from "../../components/Card/CardHeader";
import CustomButton from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';
import { CustomSweetAlert } from '../../components/CustomSweetAlert';
import { Dashboard } from '../../components/Layout';
import styles from './styles';
import { getAjaxClient } from '../../utils';

interface IContact {
    [key: string]: any
}

class ContactsForm extends Component<any, any> {

    private alertComp: any = React.createRef();

    constructor(props: any) {
        super(props);

        const data: IContact = {
            country: 'US'
        }

        this.state = {
            data: data,
            saving: false,
            groups: [],
            snack: {
                type: 'success',
                message: '',
                open: false
            }
        }
    }

    componentDidMount() {
        const { match } = this.props;

        this.setState({ saving: true });
        const loadPromise = this.loadGroups();

        if (match.params && match.params.id) {
            // this.load(match.params.id);
            loadPromise.then(() => this.load(match.params.id));
        }

        loadPromise
            .catch(error => {
                let message = 'An error has occurred while loading the contact.';
                this.alert(message, () => {
                    setTimeout(() => {
                        this.props.history.replace('/contacts');
                    }, 250);
                });
            })
            .finally(() => {
                this.setState({ saving: false });
            });

    }

    async loadGroups(): Promise<void> {

        const client = await getAjaxClient();

        return client.get('/api/v1/contactgroup')
            .then(response => {

                const groups = response.data.map((group: any) => {
                    return {
                        key: group.id,
                        label: group.name
                    }
                });
                this.setState({ groups });
            });

    }

    async load(id: string): Promise<void> {
        const client = await getAjaxClient();

        return client.get('/api/v1/contacts/' + id)
            .then(response => {
                this.setState({
                    data: {
                        id,
                        ...response.data
                    }
                });
            });
    }

    async submit() {
        const { data } = this.state,
            client = await getAjaxClient();

        if (!data.firstname) {
            return this.alert('Please enter the first name.');
        }

        if (!data.country) {
            return this.alert('Please select a country.');
        }

        if (!data.contactGroups || !Array.isArray(data.contactGroups) || data.contactGroups.length === 0) {
            return this.alert('Please assign the contact to at least one group.');
        }

        if (data.phone) {
            data.phone = (data.phone || '').replace(/\D/g, '');
        }

        this.setState({ saving: true });

        client.post('/api/v1/contacts', {
            ...data
        })
            .then(response => {
                this.setState({ saving: false });
                return this.props.history.push('/contacts');
            })
            .catch(error => {
                this.setState({ saving: false });

                let message = 'An error has occurred while saving the contact.';
                if (error.response && error.response.data) {
                    message = error.response.data.error;
                }
                this.alert(message);
            });
    }

    alert(message: string, callback?: Function) {
        this.alertComp.open({
            content: message,
            type: 'error',
            onClose: callback
        });
    }

    updateValue(key: string, value: any) {
        const data = this.state.data;
        data[key] = value;
        this.setState({ data });
    }


    render() {
        const { classes, history } = this.props,
            { id } = this.props.match.params,
            { saving, data } = this.state,
            title = id ? 'Edit Contact' : 'Add Contact';


        return (
            <Dashboard title="Contacts" history={history}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitle}>{title}</h4>
                        <p className={classes.cardSubtitle}>
                            Please enter any relevant information for your contact
                        </p>
                    </CardHeader>
                    <CardBody>
                        <Backdrop className={classes.backdrop} open={saving}>
                            <RingLoader color="#ffffff" />
                        </Backdrop>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <CustomInput
                                    labelText="First Name"
                                    id="firstname"
                                    formControlProps={{
                                        fullWidth: true,
                                        required: true
                                    }}
                                    inputProps={{
                                        value: data.firstname || '',
                                        onChange: (evt: any) => this.updateValue('firstname', evt.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomInput
                                    labelText="Last Name"
                                    id="lastname"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: data.lastname || '',
                                        onChange: (evt: any) => this.updateValue('lastname', evt.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInput
                                    labelText="Email"
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: data.email || '',
                                        onChange: (evt: any) => this.updateValue('email', evt.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomInput
                                    labelText="Phone Number"
                                    id="phone"
                                    type="phone"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: data.phone || '',
                                        onChange: (evt: any) => this.updateValue('phone', evt.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomInput
                                    labelText="Country"
                                    id="country"
                                    type="country"
                                    formControlProps={{
                                        fullWidth: true,
                                        required: true
                                    }}
                                    inputProps={{
                                        onChange: (evt: any) => {
                                            const data = this.state.data;
                                            data.country = evt.target.value;
                                            this.setState({ data });
                                        },
                                        value: this.state.data.country
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomInput
                                    labelText="Assigned Groups"
                                    id="contactGroups"
                                    type="select"
                                    formControlProps={{
                                        fullWidth: true,
                                        required: true
                                    }}
                                    data={this.state.groups || []}
                                    inputProps={{
                                        onChange: (evt: any) => this.updateValue('contactGroups', evt.target.value),
                                        value: this.state.data.contactGroups || [],
                                        multiple: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </CardBody>
                    <CardFooter>
                        <Box className={classes.toolbarIcons}>
                            <CustomButton
                                color="danger"
                                simple
                                startIcon={<CloseIcon />}
                                onClick={() => this.props.history.push('/contacts')}
                            >
                                Cancel
                            </CustomButton>
                            <CustomButton
                                color="success"
                                simple
                                startIcon={<SaveIcon />}
                                onClick={this.submit.bind(this)}
                            >
                                Save
                            </CustomButton>
                        </Box>
                    </CardFooter>
                </Card>
                <CustomSweetAlert ref={(ref: any) => this.alertComp = ref} />
            </Dashboard>);
    }

}

export default withStyles(styles)(ContactsForm);