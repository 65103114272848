import React, { PureComponent } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import CustomButton from '../CustomButtons/Button';

export class CustomSweetAlert extends PureComponent<any, CustomSweetSlertOptions> {

    constructor(props: any) {
        super(props);

        this.state = {
            open: false,
            title: ''
        };
    }

    open(opts?: CustomSweetSlertOptions) {
        const options = Object.assign({
            title: '',
            type: undefined,
            content: undefined,
            cancelable: false,
            onClose: () => { },
            onCancel: () => { }
        }, opts || {});


        this.setState({
            open: true,
            ...options
        })
    }

    onClose() {
        this.setState({ open: false });
        if (this.state.onClose)
            this.state.onClose();
    }

    onCancel() {
        this.setState({ open: false });
        if (this.state.onCancel)
            this.state.onCancel();
    }

    render() {

        const { content, title, type } = this.state;

        return (<SweetAlert
            title={title || ''}
            type={type}
            onConfirm={() => this.onClose()}
            onCancel={() => this.onCancel()}
            show={this.state.open}
            customButtons={
                <React.Fragment>
                    {this.state.cancelable && <CustomButton
                        color="danger"
                        simple
                        size="lg"
                        onClick={() => this.onCancel()}
                    >
                        Cancel
                    </CustomButton>}
                    <CustomButton
                        color="success"
                        simple
                        size="lg"
                        onClick={() => this.onClose()}
                    >
                        Ok
                    </CustomButton>
                </React.Fragment>
            }
        >
            {content}
        </SweetAlert>);
    }

}

interface CustomSweetSlertOptions {
    title: string,
    type?: SweetAlertType,
    content?: any,
    open: boolean,
    onClose?: Function,
    onCancel?: Function,
    cancelable?: boolean
}

export enum SweetAlertType {
    success = 'success',
    error = 'error',
    warning = 'warning',
    danger = 'danger',
    info = 'info'
}