import { WithStyles, createStyles, Theme } from "@material-ui/core";



export const styles = (theme: Theme) =>
    createStyles({
        flexContainer: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
        },
        table: {
            // temporary right-to-left patch, waiting for
            // https://github.com/bvaughn/react-virtualized/issues/454
            '& .ReactVirtualized__Table__headerRow': {
                flip: false,
                paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
            },
        },
        tableRow: {
            cursor: 'pointer',
            // border: '1px solid grey  '
        },
        tableRowHover: {
            '&:hover': {
                backgroundColor: theme.palette.grey[200],
            },
        },
        tableCell: {
            flex: 1,
        },
        noClick: {
            cursor: 'initial',
        },
        progress: {
            // color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
            zIndex: 1000
        },
        header: {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.text.secondary
        }
    });


export interface ColumnData {
    dataKey: string;

    /**
     * The text that will be displayed as the column title
     */
    label?: string;

    /**
     * The width of the column
     */
    width?: number;

    /**
     * The type of the column. Default as string.
     */
    type?: ColumnType | string,

    /**
     * When type is date, the format applied to the date.
     */
    format?: string,
    renderer?: (column: ColumnData, row: any) => any,

    /**
     * The align of the values of the column
     */
    align?: "left" | "right" | "inherit" | "center" | "justify" | undefined,

    classes?: Function | any
}

export interface Row {
    index: number;
}

export interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
    columns: ColumnData[];
    headerHeight?: number;
    rowCount: number;
    rowHeight?: number;
    data: Array<any>;
    id?: string;
    loadMoreRows: ({ startIndex, stopIndex }: { startIndex: number, stopIndex: number }) => Promise<Array<any>>;
    isRowLoaded?: ({ index }: { index: number }) => boolean;
    onRowClick?: (evt: any) => void;
    selectable?: boolean;

    height?: number;
    tableRef?: (ref: any) => void;
}

export interface IState {
    loading: boolean,
    selected: any
}

export enum ColumnType {
    string = 'string',
    number = 'number',
    date = 'date',
    boolean = 'boolean',
    phone = 'phone',
    select = 'select'
}