import basejoi from '@hapi/joi';
const joi = basejoi.extend(require('joi-phone-number'));

export const ContactSchema = joi.object({
    id: joi.string()
        .length(36)
        .optional(),

    firstname: joi.string()
        .min(1)
        .required(),

    country: joi.string()
        .min(2)
        .required(),

    lastname: joi.string()
        .min(1)
        .optional(),
    phone: joi
        .string()
        .phoneNumber({ defaultCountry: 'US', format: 'e164', strict: true })
        .optional()
        .empty(null)
        .allow(''),
    cellphone: joi.string()
        .min(6)
        .max(10)
        .allow('')
        .allow(null)
        .optional(),
    email: joi.string()
        .email({ tlds: { allow: false } })
        .allow('')
        .allow(null)
        .optional(),
    contactGroups: joi.array()
        .min(1)
        .optional(),

    status: joi.object({
        sms: joi.string().allow('subscribed', 'failed')
    }),

    modified: joi.number().optional(),
    created: joi.number().optional(),
});

export const ContactGroupSchema = joi.object({
    name: joi.string()
        .min(3)
        .required(),
    description: joi.string()
        .optional()
})