import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import Dashboard from "@material-ui/icons/Dashboard";
import { ContactsList } from "./pages/Contacts";
import HomePage from "./pages/Home";
import MessagesPage from "./pages/Messages";

const dashboardRoutes = [
    {
        path: "/",
        name: "Dashboard",
        rtlName: "Dashboard",
        icon: Dashboard,
        component: HomePage
    },
    {
        path: "/contacts",
        name: "Contacts",
        rtlName: "Contacts",
        icon: ContactsOutlinedIcon,
        component: ContactsList
    },
    {
        path: "/messages",
        name: "Messages",
        rtlName: "Messages",
        icon: ChatOutlinedIcon,
        component: MessagesPage
    },
];

export default dashboardRoutes;
