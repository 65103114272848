import React, { Component } from 'react';
import { getAjaxClient } from '../../utils';
import { AxiosInstance } from 'axios';

export abstract class BaseComponent<P, S> extends Component<P, S>{
    protected alertComp: any = React.createRef();

    alert(message: string, callback?: Function) {
        if (this.alertComp.open) {
            this.alertComp.open({
                content: message,
                type: 'error',
                onClose: callback
            });
        }
    }

    async getAjaxClient(): Promise<AxiosInstance> {
        return getAjaxClient();
    }
}