import { Box, Button, withStyles } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../app/defaultConnect';
import Badge from '../../components/Badge/Badge';
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon";
import Progress from '../../components/CustomLinearProgress/CustomLinearProgress';
import { CustomSweetAlert } from '../../components/CustomSweetAlert';
import { VirtualizedTable } from '../../components/InifiniteTable';
import { Dashboard } from '../../components/Layout';
import { getAjaxClient } from '../../utils';
import styles from './styles';

class MessagesList extends Component<any, any> {
    private alertComp: any = React.createRef();
    private tableRef: any = React.createRef();

    constructor(props: any) {
        super(props);

        this.state = {
            data: {
                page: 0,
                rows: []
            },
            loading: false
        }
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.load()
            .catch(error => {
                let message = 'An error has occurred while loading the contacts.';
                this.alert(message);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    loadMoreRows({ startIndex, stopIndex }: { startIndex: number, stopIndex: number }): Promise<Array<any>> {
        return this.load();
    }

    load(): Promise<Array<any>> {
        const data = this.state.data;
        data.page = data.page + 1;

        return new Promise(async (resolve, reject) => {
            const client = await getAjaxClient();

            client.get('/api/v1/message', {
                params: {
                    page: data.page,
                    pageSize: 25
                }
            })
                .then(response => {
                    let newRecords = new Array<any>();
                    if (response.data && response.data.length > 0) {
                        response.data.forEach((row: any) => {
                            data.rows.push(row);
                        });

                        newRecords = response.data;
                    }
                    this.setState({ data });

                    resolve(newRecords);
                });
        });
    }

    alert(message: string, callback?: Function) {
        this.alertComp.open({
            content: message,
            type: 'error',
            onClose: callback
        });
    }

    deleteRecords(): void {
        const records = this.tableRef.getSelected();

        if (records.length === 0) {
            return this.alert('Please select at least one record', () => { });
        }

        this.alertComp.open({
            content: 'Are you sure you want to delete this record(s)?',
            type: 'warning',
            onClose: async () => {
                const client = await getAjaxClient();

                this.setState({ loading: true });
                client.delete('/api/v1/contacts', {
                    data: records
                })
                    .then(() => {
                        this.setState({ data: { rows: [], page: 0 } });
                        this.load();
                    })
                    .catch(error => {
                        this.alert('There was a problem while deleting the records.');
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });

            },
            cancelable: true
        });
    }

    renderStatusChip(status: string) {
        switch (status) {
            case 'new': return (<Badge color="info">New</Badge>);
            case 'inprogress': return (<Badge color="warning">In Progress</Badge>);
            case 'sent': return (<Badge color="success">Sent</Badge>);
            case 'failed': return (<Badge color="danger">Failed</Badge>);
            case 'rejected': return (<Badge color="rose">Rejected</Badge>);
        }
    }

    render() {
        const { classes, history, audience } = this.props,
            { loading } = this.state;

        return (
            <Dashboard title="Scheduled Messages" history={history}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="rose">
                            <MessageIcon />
                        </CardIcon>
                        <Box className={classes.toolbarIcons}>
                            <Button
                                color="secondary"
                                className={classes.button}
                                onClick={() => history.push('/messages/schedule')}
                                startIcon={<RateReviewOutlinedIcon />}
                            >
                                Schedule
                            </Button>
                            {/* <Button
                                color="secondary"
                                onClick={this.deleteRecords.bind(this)}
                                startIcon={<DeleteOutlineOutlinedIcon />}
                            >
                                Delete
                            </Button> */}
                        </Box>
                    </CardHeader>
                    <CardBody>
                        {loading && <Progress color="rose" />}
                        <VirtualizedTable
                            tableRef={(ref: any) => this.tableRef = ref}
                            data={this.state.data.rows}
                            rowCount={this.state.data.rows.length}
                            loadMoreRows={this.loadMoreRows.bind(this)}
                            selectable
                            columns={[
                                {
                                    label: 'Status',
                                    dataKey: 'status',
                                    width: 120,
                                    renderer: (column: any, row: any) => {
                                        return this.renderStatusChip(row.status);
                                    }
                                },
                                {
                                    label: 'Audience',
                                    dataKey: 'audienceId',
                                    renderer: (column: any, row: any) => {
                                        const res = audience
                                            .filter((a: any) => (row.audienceId || []).includes(a.key))
                                            .map((a: any) => a.label);
                                        return res.join(', ');
                                    }
                                },
                                {
                                    label: 'Message',
                                    dataKey: 'message'
                                },
                                {
                                    label: 'Sent',
                                    dataKey: 'sent',
                                    type: 'number',
                                    width: 100,
                                    format: '0,0'
                                },
                                {
                                    label: 'Errors',
                                    dataKey: 'errors',
                                    type: 'number',
                                    width: 100,
                                    format: '0,0'
                                },
                                {
                                    label: 'Schedule Date',
                                    dataKey: 'scheduleDate',
                                    type: 'date'
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
                <CustomSweetAlert ref={(ref: any) => this.alertComp = ref} />
            </Dashboard>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MessagesList));