import { Box, Button, withStyles } from '@material-ui/core';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon";
import { CustomSweetAlert } from '../../components/CustomSweetAlert';
import { VirtualizedTable } from '../../components/InifiniteTable';
import { Dashboard } from '../../components/Layout';
import { getAjaxClient } from '../../utils';
import Progress from '../../components/CustomLinearProgress/CustomLinearProgress'
import styles from './styles';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../app/defaultConnect';

class ContactsList extends Component<any, any> {
    private alertComp: any = React.createRef();
    private tableRef: any = React.createRef();

    constructor(props: any) {
        super(props);

        this.state = {
            data: {
                page: 0,
                rows: []
            },
            groups: [],
            loading: false
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.load()
            .catch(error => {
                let message = 'An error has occurred while loading the contacts.';
                this.alert(message);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }


    loadMoreRows({ startIndex, stopIndex }: { startIndex: number, stopIndex: number }): Promise<Array<any>> {
        return this.load();
    }

    load(): Promise<Array<any>> {
        const data = this.state.data;
        data.page = data.page + 1;

        return new Promise(async (resolve, reject) => {
            const client = await getAjaxClient();

            client.get('/api/v1/contacts', {
                params: {
                    page: data.page,
                    pageSize: 25,
                    sorting: {
                        field: 'firstname',
                        dir: 'asc'
                    }
                }
            })
                .then(response => {
                    let newRecords = new Array<any>();
                    if (response.data && response.data.length > 0) {
                        response.data.forEach((row: any) => {
                            data.rows.push(row);
                        });

                        newRecords = response.data;
                    }
                    this.setState({ data });

                    resolve(newRecords);
                });
        });
    }

    alert(message: string, callback?: Function) {
        this.alertComp.open({
            content: message,
            type: 'error',
            onClose: callback
        });
    }

    deleteRecords(): void {
        const records = this.tableRef.getSelected();

        if (records.length === 0) {
            return this.alert('Please select at least one record', () => { });
        }

        this.alertComp.open({
            content: 'Are you sure you want to delete this record(s)?',
            type: 'warning',
            onClose: async () => {
                const client = await getAjaxClient();

                this.setState({ loading: true });
                client.delete('/api/v1/contacts', {
                    data: records
                })
                    .then(() => {
                        this.setState({ data: { rows: [], page: 0 } });
                        this.load();
                    })
                    .catch(error => {
                        this.alert('There was a problem while deleting the records.');
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });

            },
            cancelable: true
        });
    }

    render() {
        const { classes, history, audience } = this.props,
            { loading } = this.state;

        return (
            <Dashboard title="Contacts" history={history}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="rose">
                            <ContactsOutlinedIcon />
                        </CardIcon>
                        <Box className={classes.toolbarIcons}>
                            <Button
                                color="secondary"
                                className={classes.button}
                                onClick={() => history.push('/contacts/edit')}
                                startIcon={<PersonAddOutlinedIcon />}
                            >
                                Add
                            </Button>
                            <Button
                                color="secondary"
                                className={classes.button}
                                onClick={() => history.push('/contacts/upload')}
                                startIcon={<BackupOutlinedIcon />}
                            >
                                Upload
                            </Button>
                            <Button
                                color="secondary"
                                onClick={this.deleteRecords.bind(this)}
                                startIcon={<DeleteOutlineOutlinedIcon />}
                            >
                                Delete
                            </Button>
                        </Box>
                    </CardHeader>
                    <CardBody>
                        {loading && <Progress color="rose" />}
                        <VirtualizedTable
                            tableRef={(ref: any) => this.tableRef = ref}
                            data={this.state.data.rows}
                            rowCount={this.state.data.rows.length}
                            loadMoreRows={this.loadMoreRows.bind(this)}
                            selectable
                            columns={[
                                {
                                    label: 'Name',
                                    dataKey: 'firstname',
                                    renderer: (column: any, row: any) => {
                                        const name = row.firstname || row.lastname ? `${row.firstname || ''} ${row.lastname || ''}` : '-';
                                        return (<Link to={"/contacts/edit/" + row.id}>{name}</Link>);
                                    }
                                },
                                {
                                    label: 'Phone',
                                    dataKey: 'phone',
                                    type: 'phone'
                                },
                                {
                                    label: 'Country',
                                    dataKey: 'country'
                                },
                                {
                                    label: 'Groups',
                                    dataKey: 'contactGroups',
                                    renderer: (column: any, row: any) => {
                                        const res = audience
                                            .filter((g: any) => (row.contactGroups || []).includes(g.key))
                                            .map((g: any) => g.label);
                                        return res.join(', ');
                                    }
                                },
                                {
                                    label: 'Modified',
                                    dataKey: 'modified',
                                    type: 'date'
                                },
                            ]}
                        />
                    </CardBody>
                </Card>
                <CustomSweetAlert ref={(ref: any) => this.alertComp = ref} />
            </Dashboard>);
    }

}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContactsList));