import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles: (theme: Theme) => StyleRules<string> = theme =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    });

export default styles;